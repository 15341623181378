import React from "react";
import { Container } from "react-bootstrap";

const Commercialhero = () => {
  return (
    <div className="overlay-hero banner banner-page banner-commercial mb-30">
      <Container>
        <div className="mb-30">
          <h1 className="banner-head mb-30">Commercial Building Inspections</h1>
        </div>
      </Container>
    </div>
  );
};

export default Commercialhero;
