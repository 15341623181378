import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import CTA1 from "../components/CTA1";
import Commercialhero from "../components/hero/Commercialhero";

const CBI = () => {
  return (
    <Layout>
      <Commercialhero />
      <Container>
        <Row>
          <Col>
            <h3>Commercial Inspections</h3>
            <p>
              Young Home Inspections has been providing quality commercial
              inspections for over 30 years. If you have a commercial building
              or are looking to buy one, it is important to make sure you know
              the status of the facility. We provide thorough commercial
              building inspections for multi-purpose failities, multi-unit
              commercial, multi-unit residential, as well as strip malls,
              shopping centers, warehouses and other commercial structures.
            </p>
            <p>
              Unlike with a home inspection or other residential inspections, a
              commercial inspector, will need to assess a larger number systems
              in the entire building. The commercial building inspector will
              need to comb the floor plan and out buildings to ensure that all
              systems are up-to-code and working well.
            </p>
            <p>
              Each commercial building inspector on our team will provide you
              with a full array of inspection services. We look over each
              property as a complete building system. Your inspector will follow
              a detailed commercial building inspection checklist and follow-up
              by providing you with a detailed report. The final report will be
              provided as a thorough commercial building inspection report that
              meets or surpasses inspection industry standards and gives you, as
              a property owner, the peace of mind you deserve.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Commercial Building Inspections</h3>
            <p>
              Commercial Building Inspections or Commercial Inspections provide
              interested parties the knowledge and documentation needed to make
              an informed decision about a property or properties. As with any
              transaction in which negotiation can occur, the more you know the
              better prepared you will be.
            </p>
            <p>
              Many property condition assessments, demonstrate that many
              properties are definitely not in pristine condition. Surprisingly
              this sometimes holds true for even a brand-new commercial
              building. It is important even when properties are newer that you
              know if the property has been maintained properly, this goes
              doubly so for older ones.
            </p>
            <p>
              If not maintained properly, with regular maintenance, this can
              cause major components to age prematurely requiring repair and
              replacement before their normal time. Whether you are a tenant,
              seller, landlord, lender, portfolio manager or even a team of
              asset managers, you want to know what is next on that list of
              replacements or necessary repairs. This is especially true with
              older properties.
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="fifty-split-2">
            <StaticImage src="../images/commercial-top.jpg" />
          </Col>
          <Col lg={6} className="fifty-split-1">
            <p>
              We cannot stress enough that if you are considering leasing or
              purchasing a commercial property it is extremely important to
              practice your due diligence and get all the information and
              history possible about that property before closing or signing
              anything. This due diligence needed absolutely requires a licensed
              and experienced Commercial building Inspector.
            </p>
            <p>
              Our commercial inspectors have saved investors and commercial
              clients countless amounts of money that they could have
              potentially lost by discovering a building’s true condition that
              would have been missed if we hadn’t performed a Commercial
              Property Inspection with a professional inspector.
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <p>
              All of our Building Inspections cover all major systems and
              components of commercial properties. This includes: the structure
              of all buildings and surrounding features, foundation, roofing
              system, exterior coverings, electrical, plumbing, HVAC,
              ventilation, interior coverings and fixtures, vertical lift
              systems, and life/ safety/ fire/protection equipment.
            </p>
            <p>
              So if you have an interest in a commercial property and would like
              to know all you can about its condition, life expectancy, and
              repair cost estimate, it is extremely important that you get a
              Commercial Building Inspection from a licensed inspector, so you
              can make the right decision in terms of negotiating an appropriate
              price or you can simply walk away from a building that has too
              many problems and hasn’t been properly maintained.
            </p>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <StaticImage src="../images/commercial-mid.jpg" />
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Regardless of which direction your inspection report takes you,
              you can rest assured knowing that you were armed with the most
              comprehensive checklist and commercial building inspection report
              available to make your decision.
            </p>
            <p>
              If you need any help or have any questions we are always here to
              serve you with professionalism. Feel free to contact us at any
              time because we love helping people make the right decisions for
              their businesses and themselves.
            </p>
            <p>Schedule your commercial property inspection now!</p>
          </Col>
        </Row>
      </Container>
      <CTA1 />
    </Layout>
  );
};

export default CBI;
